import { Box, Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import { ContentWrapper } from "./StyledComponents";
import Logo from "../assets/MBS-Fade.svg";
import Logo2 from "../assets/MBS-black.svg";

class Header extends Component {
  render() {
    return (
      <ContentWrapper>
        <Grid container>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Typography
              variant="h1"
              sx={(theme) => ({
                fontSize: { xs: "48px", md: "64px" },
                fontWeight: "800",
                letterSpacing: "-2.56px",
                lineHeight: { xs: "100%", md: "64px" },
                marginBottom: "32px",
                marginTop: "64px",
                // textAlign: {
                //   [theme.breakpoints.down("sm")]: "center",
                // },
                [theme.breakpoints.down("sm")]: {
                  textAlign: "center",
                  maxWidth: "500px",
                },
              })}
            >
              Music. Fashion. Lifestyle.
            </Typography>
            <Typography
              variant="body1"
              sx={(theme) => ({
                fontSize: "20px",
                lineHeight: { xs: "1.6", md: "32px" },
                [theme.breakpoints.down("sm")]: {
                  textAlign: "center",
                },
              })}
            >
              Mehrbums ist deine Veranstaltungsreihe für jegliche Art
              elektronischer Musik. Egal ob Bass House, Hardstyle oder Techno,
              bei unseren Parties ist für jeden etwas dabei. Informiere dich
              einfach über unsere nächsten Dates oder frag uns für deine eigene
              Mehrbums-Veranstaltung an.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            alignItems="center"
            justifyContent="center"
            order={{ xs: 1, md: 2 }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                width: "90%",
                justifyContent: "center",
              }}
            >
              <img src={Logo} alt="Logo" style={{ width: "90%" }} />
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                width: "90%",
                justifyContent: "center",
              }}
            >
              <img src={Logo2} alt="Logo" style={{ width: "90%" }} />
            </Box>
          </Grid>
        </Grid>
      </ContentWrapper>
    );
  }
}

export default Header;
