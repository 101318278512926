import { Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import { SectionWrapper } from "./StyledComponents";
import MusicNoteRoundedIcon from "@mui/icons-material/MusicNoteRounded";

class Genres extends Component {
  render() {
    return (
      <SectionWrapper>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              sx={{ maxWidth: "416px", marginBottom: "32px" }}
            >
              Ein breites Spektrum. Unsere{" "}
              <Typography variant="span" color="textH2.light">
                Genres der elektronischen Musik.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} container rowSpacing={1}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MusicNoteRoundedIcon />
                Bass House
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MusicNoteRoundedIcon />
                EDM
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <MusicNoteRoundedIcon />
                Hardstyle
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <MusicNoteRoundedIcon />
                Techno
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <MusicNoteRoundedIcon />
                Trap
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <MusicNoteRoundedIcon />
                Dubstep
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <MusicNoteRoundedIcon />
                Goa/Psy-Trance
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <MusicNoteRoundedIcon />
                and many more ...
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </SectionWrapper>
    );
  }
}

export default Genres;
