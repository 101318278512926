import {
  Box,
  ButtonBase,
  Grid,
  IconButton,
  Link,
  Modal,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import { SectionWrapper } from "./StyledComponents";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import Farell from "../assets/edm-edition-plakat.jpg";
import LeShuuk from "../assets/leshuuk.jpg";

class Dates extends Component {
  state = {
    modalOpen: false,
    modalContent: null,
  };

  handleClose = () => {
    this.setState({ modalOpen: false, modalContent: null });
  };

  render() {
    const { modalOpen, modalContent } = this.state;

    return (
      <SectionWrapper>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              sx={{ maxWidth: "416px", marginBottom: "32px" }}
            >
              Feiere mit uns bei unseren nächsten{" "}
              <Typography variant="span" color="textH2.light">
                Events.
              </Typography>
            </Typography>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Grid item>
              <ButtonBase
                onClick={() =>
                  this.setState({ modalOpen: true, modalContent: LeShuuk })
                }
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "17px",
                    lineHeight: "1.7",
                  }}
                >
                  <OpenInNewRoundedIcon
                    sx={{
                      fontSize: { xs: "14px" },
                      position: "relative",
                      top: "1px",
                    }}
                  />
                  <Box component="span">
                    {" "}
                    26.05.2023 - S-Club Fulda - Hardstyle Edition mit Le Shuuk
                  </Box>
                </Typography>
              </ButtonBase>
            </Grid>
            <Grid item>
              <ButtonBase
                onClick={() =>
                  this.setState({ modalOpen: true, modalContent: Farell })
                }
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "17px",
                    lineHeight: "1.7",
                  }}
                >
                  <OpenInNewRoundedIcon
                    sx={{
                      fontSize: { xs: "14px" },
                      position: "relative",
                      top: "1px",
                    }}
                  />
                  <Box component="span">
                    {" "}
                    27.01.2023 - S-Club Fulda - Festival Edition mit Fabian
                    Farell
                  </Box>
                </Typography>
              </ButtonBase>
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={modalOpen}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img
              src={modalContent}
              alt="wide1"
              style={{
                width: "auto",
                maxWidth: "90vw",
                height: "auto",
                maxHeight: "90vh",
              }}
            />
          </Box>
        </Modal>
      </SectionWrapper>
    );
  }
}

export default Dates;
