import { Box, Grid, IconButton, Link, Typography } from "@mui/material";
import React, { Component } from "react";
import { SectionWrapper } from "./StyledComponents";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

class Fashion extends Component {
  render() {
    return (
      <SectionWrapper>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              sx={{ maxWidth: "416px", marginBottom: "32px" }}
            >
              Zeige deinen{" "}
              <Typography variant="span" color="textH2.light">
                Lifestyle
              </Typography>{" "}
              mit unseren{" "}
              <Typography variant="span" color="textH2.light">
                Mehrbums Fashion Shirts.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body2"
              sx={{ fontSize: "17px", lineHeight: "1.7" }}
            >
              Du hast Bock auf eines unserer Shirts? Schreib uns einfach auf{" "}
              <Link
                href="https://www.instagram.com/_mehrbums/?hl=de"
                target="_blank"
                rel="noreferrer"
                color="body2.main"
              >
                Instagram
              </Link>{" "}
              oder{" "}
              <Link
                href="https://de-de.facebook.com/mehrbums/?ref=page_internal"
                target="_blank"
                rel="noreferrer"
                color="body2.main"
              >
                Facebook
              </Link>{" "}
              für mehr Infos. Wir planen bereits weiteren Merch, u.a. einem
              neuen Mehrbums-Hoodie. More coming soon...
            </Typography>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                  position: "relative",
                  left: "-8px",
                },
                [theme.breakpoints.down("md")]: {
                  display: "flex",
                  justifyContent: "center",
                },
              })}
            >
              <IconButton
                color="body2"
                href="https://www.instagram.com/_mehrbums/?hl=de"
                target="_blank"
              >
                <InstagramIcon sx={{ fontSize: { xs: "36px" } }} />
              </IconButton>
              <IconButton
                color="body2"
                href="https://de-de.facebook.com/mehrbums/?ref=page_internal"
                target="_blank"
              >
                <FacebookIcon sx={{ fontSize: { xs: "36px" } }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </SectionWrapper>
    );
  }
}

export default Fashion;
