import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Main from "./components/Main";

const palette = {
  //primary:
  textMain: {
    main: "#2d4665",
  },
  textH1: {
    main: "#03060B",
  },
  textH2: {
    main: "#100f22",
    light: "#7a798d",
  },
  textH3: {
    main: "#03060B",
  },
  body2: {
    main: "#2D4665",
  },
  genreIconColor: {
    main: "#4c48ff",
  },
  footer: {
    main: "#626265",
  },
};

const theme = createTheme({
  typography: {
    fontFamily: `Inter, "Inter UI", Helvetica, Arial, sans-serif;`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: palette,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "h2",
          h4: "h2",
          h5: "h2",
          h6: "h2",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "span",
          body2: "span",
          body3: "span",
        },
      },
      variants: [
        {
          props: { variant: "body1" },
          style: {
            color: palette.textMain.main,
          },
        },
        {
          props: { variant: "h2" },
          style: {
            color: palette.textH2.main,
            fontWeight: 600,
            fontSize: "32px",
            lineHeight: "1.2",
            letterSpacing: "-0.018em",
          },
        },
        {
          props: { variant: "h1" },
          style: {
            color: palette.textH1.main,
          },
        },
        {
          props: { variant: "h3" },
          style: {
            color: palette.textH3.main,
            fontSize: "20px",
            fontWeight: 600,
          },
        },
        {
          props: { variant: "body2" },
          style: {
            color: palette.body2.main,
            fontSize: "14px",
            lineHeight: "1.4",
          },
        },
      ],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  );
}

export default App;
