import { styled } from "@mui/system";

export const ContentWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    maxWidth: "600px",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "900px",
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "1200px",
  },
  margin: "0 auto",
  paddingLeft: "12px",
  paddingRight: "12px",
  marginBottom: "140px",
}));

export const SectionWrapper = styled("section")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    maxWidth: "600px",
    paddingTop: "80px",
    paddingBottom: "80px",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "900px",
    paddingTop: "160px",
    paddingBottom: "160px",
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "1200px",
  },
  margin: "0 auto",
  paddingLeft: "12px",
  paddingRight: "12px",
}));
