import { Box, Grid, IconButton, SvgIcon, Typography } from "@mui/material";
import React, { Component } from "react";
import { ContentWrapper } from "./StyledComponents";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

function TwitchIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z" />
    </SvgIcon>
  );
}

function MixcloudIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M21.95 19.062c-.154 0-.31-.045-.445-.135-.369-.25-.465-.75-.225-1.11.738-1.094 1.125-2.381 1.125-3.719s-.387-2.625-1.125-3.721c-.249-.368-.145-.866.216-1.106.375-.249.87-.146 1.108.214.917 1.365 1.396 2.97 1.396 4.62 0 1.648-.479 3.254-1.396 4.619-.135.239-.39.359-.645.359l-.009-.021zM19.66 17.768c-.153 0-.308-.045-.445-.139-.369-.239-.463-.734-.215-1.094.489-.721.747-1.545.747-2.43 0-.855-.258-1.695-.747-2.431-.248-.36-.154-.854.215-1.095s.857-.15 1.106.225c.669.99 1.021 2.145 1.021 3.314 0 1.201-.352 2.34-1.021 3.315-.146.24-.406.36-.661.36v-.025zm-3.73-7.153c-.314-3.197-3.016-5.699-6.3-5.699-2.721 0-5.13 1.748-5.995 4.283C1.588 9.501 0 11.269 0 13.4c0 2.344 1.912 4.254 4.26 4.254h10.908c1.964 0 3.566-1.594 3.566-3.557 0-1.706-1.2-3.129-2.805-3.48v-.002zm-.762 5.446H4.263c-1.466 0-2.669-1.191-2.669-2.658 0-1.465 1.193-2.658 2.669-2.658.71 0 1.381.285 1.886.781.3.314.811.314 1.125 0 .3-.301.3-.811 0-1.125-.555-.542-1.231-.931-1.965-1.111.75-1.665 2.43-2.774 4.305-2.774 2.609 0 4.74 2.129 4.74 4.738 0 .512-.075 1.006-.24 1.486-.135.42.09.869.51 1.02.074.03.165.045.24.045.33 0 .645-.211.75-.54.105-.315.18-.63.225-.96.734.285 1.26 1.005 1.26 1.83 0 1.096-.885 1.979-1.965 1.979l.034-.053z" />
    </SvgIcon>
  );
}

class Socials extends Component {
  render() {
    return (
      <Box
        sx={{
          borderBottom: "1px solid #EBEBF7",
        }}
      >
        <ContentWrapper
          sx={{
            marginBottom: 0,
            paddingBottom: "80px",
          }}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                maxWidth: "320px",
                marginBottom: "40px",
              }}
            >
              Folge uns auf unseren Social Media Kanälen
            </Typography>
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            //sx={{ height: "37px" }}
          >
            <Grid item>
              <IconButton
                color="textH3"
                href="https://www.instagram.com/_mehrbums/?hl=de"
                target="_blank"
              >
                <InstagramIcon sx={{ fontSize: { xs: "36px", md: "48px" } }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="textH3"
                href="https://de-de.facebook.com/mehrbums/?ref=page_internal"
                target="_blank"
              >
                <FacebookIcon sx={{ fontSize: { xs: "36px", md: "48px" } }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="textH3"
                href="https://www.youtube.com/channel/UC4hRAPI_i1WFqMrBEcNyKAA"
                target="_blank"
              >
                <YouTubeIcon sx={{ fontSize: { xs: "36px", md: "48px" } }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="textH3"
                href="https://www.mixcloud.com/mehrbums/"
                target="_blank"
              >
                <MixcloudIcon sx={{ fontSize: { xs: "36px", md: "48px" } }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="textH3"
                href="https://www.twitch.tv/mehrbumstv"
                target="_blank"
              >
                <TwitchIcon sx={{ fontSize: { xs: "36px", md: "48px" } }} />
              </IconButton>
            </Grid>
          </Grid>
        </ContentWrapper>
      </Box>
    );
  }
}

export default Socials;
