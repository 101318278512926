import { Box } from "@mui/system";
import React, { Component } from "react";
import Logo from "../assets/MBS-black.svg";

class Navbar extends Component {
  render() {
    return (
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            maxWidth: (theme) => theme.breakpoints.values["lg"],
            margin: "0 auto",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: "120px",
              padding: "8px",
              margin: 0,
            }}
          />
        </Box>
      </Box>
    );
  }
}

export default Navbar;
