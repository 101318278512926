import { Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import { SectionWrapper } from "./StyledComponents";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import HeadphonesRoundedIcon from "@mui/icons-material/HeadphonesRounded";

class Events extends Component {
  render() {
    return (
      <SectionWrapper>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{ maxWidth: "416px", marginBottom: "32px" }}
            >
              Unsere Parties sind legendär.{" "}
              <Typography variant="span" color="textH2.light">
                Mehrbums als Veranstaltungsreihe.
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{ marginTop: { xs: "48px", md: "96px" } }}
          >
            <Grid item xs={12} md={4}>
              <Typography
                variant="h3"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <CelebrationRoundedIcon sx={{ mr: 1 }} />
                Editionen
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: { xs: "100%", md: "270px" },
                  lineHeight: "1.7",
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Unsere Parties folgen immer einem passenden Motto. Wir setzen
                jedes Genre in eine passende Party-Edition um.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h3"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  marginTop: { xs: "32px", md: 0 },
                }}
              >
                <HeadphonesRoundedIcon sx={{ mr: 1 }} />
                DJs
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: { xs: "100%", md: "270px" },
                  lineHeight: "1.7",
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Eine gute Party braucht gute DJs. Wir legen Wert auf erfahrene
                und professionelle DJs. Auch Booking und Betreung von größeren
                Künstlern übernehmen wir gerne.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h3"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  marginTop: { xs: "32px", md: 0 },
                }}
              >
                <VerifiedUserRoundedIcon sx={{ mr: 1 }} />
                Professionalität
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: { xs: "100%", md: "270px" },
                  lineHeight: "1.7",
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Wir agieren immer professionell und stimmen alle wichtigen Infos
                mit dem Veranstalter ab.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </SectionWrapper>
    );
  }
}

export default Events;
