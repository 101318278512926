import { Box } from "@mui/material";
import React, { Component } from "react";
import Events from "./Events";
import Fashion from "./Fashion";
import Footer from "./Footer";
import Genres from "./Genres";
import Header from "./Header";
import Navbar from "./Navbar";
import Socials from "./Socials";
import WideImg from "../assets/wide_bw.jpg";
import WideImg2 from "../assets/gray_bg2.jpg";
import Dates from "./Dates";

class Main extends Component {
  render() {
    return (
      <Box>
        <Navbar />
        <Box
          component="main"
          sx={{
            paddingTop: { xs: "80px", md: "80px" },
          }}
        >
          <Header />
          <Socials />
          <Dates />
          <img src={WideImg} alt="wide1" style={{ width: "100%" }} />
          <Genres />
          <img src={WideImg2} alt="wide1" style={{ width: "100%" }} />
          <Events />
          <Fashion />
        </Box>
        <Footer />
      </Box>
    );
  }
}

export default Main;
